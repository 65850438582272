<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<DgSpacer
			:size="'100vh'"
			:sizeOnSm="'50vh'"
			:isHidden="false"
			:isHiddenOnSm="false"
		></DgSpacer>

	2022-02-24	feature: added sizeOnSm prop.
	2021-09-24	init

-->

<template>
	<div class="DgSpacer contentModule" :class="elmClasses" :data-dev-name="'DgSpacer • ' + _size" v-if="isVisible">
		<div class="DgSpacer__inner contentModule__inner" :class="innerElmClasses">
			<div class="DgSpacer__content contentModule__content fontStyle--m" :class="contentElmClasses"></div>
		</div>

		<!--
		<DgEditComponentInner></DgEditComponentInner>
		-->

		<!--
		<pre name="isHidden">{{isHidden}}</pre>
		<pre name="isHiddenOnSm">{{isHiddenOnSm}}</pre>
		<pre name="isVisible">{{isVisible}}</pre>
		-->
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import dgContentModuleMixin from '@/mixins/dgContentmodule.mixin'
	//import DgEditComponentInner from '@/components/editor/DgEditComponentInner.vue'

	export default {
		name: 'DgSpacer',
		components: {
			//DgEditComponentInner,
		},
		mixins: [ dgContentModuleMixin ],
		props: {
			size: {
				type     : [String],
				default  : 'default',
				required : false,
			},
			sizeOnSm: {
				type     : [String],
				default  : 'half',
				required : false,
			},
			isHidden: {
				type     : [Boolean],
				default  : false,
				required : false,
			},
			isHiddenOnSm: {
				type     : [Boolean],
				default  : false,
				required : false,
			},
		},
		data(){
			return {}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				classes.push( this.$options.name + '--size-' + this._size )

				return classes
			},
			innerElmClasses(){
				let classes = []

				//classes.push( 'font--size' + this._.upperFirst( this.fontSize ) )

				return classes
			},
			contentElmClasses(){
				let classes = []

				return classes
			},
			_size(){
				return this.$root.isBelowMd ? this.sizeOnSm : this.size
			},
			isVisible(){
				let isVisible = true

				if( this.$root.isBelowMd && this.isHiddenOnSm ) isVisible = false
				if( !this.$root.isBelowMd && this.isHidden ) isVisible = false

				return isVisible
			},
		},
		methods: {},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.DgSpacer { // debug
		[showBorders2] & {}
	}
	.DgSpacer { // layout
	}
	.DgSpacer { // styling
		@height : 5.45em; // 120px

		&--size-half &__content { height: @height*0.5; }
		&--size-default &__content { height: @height*1; }
		&--size-double &__content { height: @height*2; }
		&--size-100vh &__content { height: 100vh; }
		&--size-50vh &__content { height: 50vh; }

		/*
		@media @mq[sm] {
			&--size-double &__content { height: @height*0.5; }
			&--size-default &__content { height: @height*0.5; }
			&--size-100vh &__content { height: @height*0.5; }
			&--size-50vh &__content { height: @height*0.5; }
		}
		*/
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
