<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<DgSuperZoomLayer></DgSuperZoomLayer>

	2021-09-24	init

-->

<template>
	<div class="DgSuperZoomLayer" :class="elmClasses" :data-dev-name="'DgSuperZoomLayer'">
		<div class="DgSuperZoomLayer__inner">
			<div class="DgSuperZoomLayer__bg" @click="isActive = false"></div>
			<div class="DgSuperZoomLayer__nav">
				<DbButton
					@click.native="isActive = false"
					:type="'cross'"
					:isCircle="true"
				></DbButton>
			</div>
			<div class="DgSuperZoomLayer__dragScrollContainer hideScrollbars" v-dragscroll>
				<MhImage :imageObject="image"></MhImage>
			</div>
			<!--
			<pre name="menubarIsHidden">{{menubarIsHidden}}</pre>
			<pre name="articlePosts">{{articlePosts}}</pre>
			-->
		</div>
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import MhImage from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhImage/v3/MhImage.vue'
	import DbButton from '@/components/molecules/DgButton.vue'

	export default {
		name: 'DgSuperZoomLayer',
		components: {
			MhImage,
			DbButton,
		},
		mixins: [],
		//directives: { dragscroll },
		props: {},
		data(){
			return {
				image : null,
				isActive : false,
			}
		},
		watch: {
			// disable scrolling on html + body
			'isActive': {
				handler: function( to, from ){
					// disable scrolling on html + body
					if( to ) document.documentElement.style.overflow = 'hidden'
					else document.documentElement.style.overflow = ''
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				if( this.isActive ) classes.push( this.$options.name + '--isActive')
				else classes.push( this.$options.name + '--isInActive')

				return classes
			},
		},
		methods: {
			onAfterKeydown( e ){
				if( e.key === 'Escape' ) this.isActive = false
			},
		},
		created(){
			window.addEventListener('keydown', this.onAfterKeydown)
		},
		mounted(){
			EventBus.$on('doOpenSuperZoom', ( payload, doLog = true )=>{
				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• on doOpenSuperZoom' )
					console.log('payload:', payload)
					console.groupEnd()
				}

				this.isActive = true
				this.image = payload
			})
		},
		destroyed(){
			window.removeEventListener('keydown', this.onAfterKeydown)
   			EventBus.$off('doOpenSuperZoom')
 		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.DgSuperZoomLayer { // debug
		[showBorders2] & {}
	}
	.DgSuperZoomLayer { // layout
		position: fixed !important;
		top: 0; left: 0; right: 0; bottom: 0;
		z-index: 100;
		pointer-events: none;
		opacity: 0;

		&--isActive {
			pointer-events: auto;
			opacity: 1;
		}

		&__bg {
			position: absolute;
			top: 0; right: 0; bottom: 0; left: 0;
			background-color: fade( black, 75 );
			z-index: 1;
		}
		&__nav {
			position: absolute;
			top: 0; right: 0;
			z-index: 3;
		}
		&__dragScrollContainer {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			overflow: auto;
			z-index: 2;
		}
		.MhImage { width: 200vw; }
	}
	.DgSuperZoomLayer { // styling
		transition: all 0.25s ease;

		&__bg {
			background-color: white;
		}
		&__nav {
			padding-top: var(--app-gui-vPadding);
			padding-right: var(--app-gui-hPadding);
			padding-bottom: var(--app-gui-vPadding);
			padding-left: var(--app-gui-hPadding);
		}
		&__dragScrollContainer { // grab cursors
			cursor: grab;
    		cursor: -moz-grab;
    		cursor: -webkit-grab;

			&:active {
				cursor: grabbing;
    			cursor: -moz-grabbing;
    			cursor: -webkit-grabbing;
			}
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
