<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<DgQuote></DgQuote>

	2021-09-24	init

-->

<template>
	<div class="DgQuote contentModule" :class="elmClasses" :data-dev-name="'DgQuote • ' + fontSize">
		<div class="DgQuote__inner contentModule__inner" :class="innerElmClasses">
			<div class="DgQuote__content contentModule__content" :class="contentElmClasses">
				<svg class="DgQuote__icon" :class="iconElmClasses" viewBox="0 0 136 113" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M0 70.5747C0 50.1999 4.88823 34.2485 14.6647 22.7206C24.5751 11.1928 38.4362 3.61922 56.2481 0V20.911C42.7218 25.0664 33.6149 32.573 28.9276 43.4306C26.2491 49.1945 25.1777 54.7574 25.7134 60.1192H57.2526V113H0V70.5747Z" fill="currentColor"/>
					<path d="M78.626 70.5747C78.626 50.1999 83.5142 34.2485 93.2907 22.7206C103.201 11.1928 117.062 3.61922 134.874 0V20.911C121.348 25.0664 112.241 32.573 107.554 43.4306C104.875 49.1945 103.804 54.7574 104.339 60.1192H135.879V113H78.626V70.5747Z" fill="currentColor"/>
				</svg>
				<div class="DgQuote__text hideIfEmpty richText" :class="textElmClasses" v-html="text"></div>
				<div class="DgQuote__author hideIfEmpty" :class="authorElmClasses" v-html="author"></div>
			</div>
		</div>

		<!--
		<DgEditComponentInner></DgEditComponentInner>
		-->
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import dgContentModuleMixin from '@/mixins/dgContentmodule.mixin'
	//import DgEditComponentInner from '@/components/editor/DgEditComponentInner.vue'

	export default {
		name: 'DgQuote',
		components: {
			//DgEditComponentInner,
		},
		mixins: [ dgContentModuleMixin ],
		props: {
			//imageObject: [Object, Boolean],
			//DgQuote: [String],
			fontSize: {
				type     : [String],
				default  : 'xxl',
				required : false,
			},
			text: {
				type     : [String],
				default  : '',
				required : false,
			},
			author: {
				type     : [String],
				default  : '',
				required : false,
			},
		},
		data(){
			return {}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				classes.push( this.$options.name + '--size-' + this.fontSize)

				return classes
			},
			innerElmClasses(){
				let classes = []

				//classes.push( 'font--size' + this._.upperFirst( this.fontSize ) )

				return classes
			},
			contentElmClasses(){
				let classes = []

				//classes.push( 'font--size-' + this.fontSize )

				return classes
			},
			iconElmClasses(){
				let classes = []

				if( 'xxl' === this.fontSize ) classes.push( 'fontStyle--xxl' )
				if( 'xl' === this.fontSize ) classes.push( 'fontStyle--xl' )

				return classes
			},
			textElmClasses(){
				let classes = []

				classes.push( 'fontStyle--' + this.fontSize )

				if( this.fontSize !== 'xxl' ) classes.push( 'hyphenate' )

				//if( 'xxxl' === this.fontSize ) classes.push( 'fontStyle--xxl' )
				//if( 'default' === this.fontSize ) classes.push( 'font--size-big' )

				return classes
			},
			authorElmClasses(){
				let classes = []

				if( 'xxl' === this.fontSize ) classes.push( 'fontStyle--l' )
				if( 'xl' === this.fontSize ) classes.push( 'fontStyle--l' )

				return classes
			},
		},
		methods: {},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.DgQuote { // debug
		[showBorders2] & {}

		/*
		&__icon { background-color: fade(red, 20); }
		&__text { background-color: fade(green, 20); }
		&__author { background-color: fade(red, 20); }
		*/
	}
	.DgQuote { // layout
		// grid
		.DgGrid--cols-1 &__content { grid-column: 3 / span 8; }
		.DgGrid--cols-2 &__content { grid-column: 1 / span 6; }

		@media @mq[sm] {
			// grid
			.DgGrid--cols-1 &__content { grid-column: 1 / span 12; }
			.DgGrid--cols-2 &__content { grid-column: 1 / span 12; }
		}
	}
	.DgQuote { // styling
		&__icon { width: 1.5em; margin-bottom: 0.2em; } // em is based on text size
		&__text { margin-bottom: 0.5em; }
		//&__author { padding-top: 0.25em; }
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
