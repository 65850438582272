<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<DgSocialBtns></DgSocialBtns>

	2021-09-24	init

-->

<template>
	<div class="DgSocialBtns" :class="elmClasses">
		<DgButton :type="'youtube'" :isCircle="true" :isOutlined="true" href="https://www.youtube.com/user/StiftungOpelvillen" target="_blank"></DgButton>
		<DgButton :type="'twitter'" :isCircle="true" :isOutlined="true" href="https://twitter.com/opelvillen" target="_blank"></DgButton>
		<DgButton :type="'facebook'" :isCircle="true" :isOutlined="true" href="https://www.facebook.com/OpelvillenRuesselsheim/" target="_blank"></DgButton>
		<DgButton :type="'instagram'" :isCircle="true" :isOutlined="true" href="https://www.instagram.com/opelvillen/" target="_blank"></DgButton>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'
	import DgButton from '@/components/molecules/DgButton.vue'

	export default {
		name: 'DgSocialBtns',
		components: {
			DgButton,
		},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
			myProp: {
				type     : [String, Boolean],
				default  : false,
				required : false,
			},
		},
		data(){
			return {}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
		},
		methods: {},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.DgSocialBtns { // debug
		[showBorders2] & {}
	}
	.DgSocialBtns { // layout
		display: flex;
	}
	.DgSocialBtns { // styling
		.DgButton {
			//--bgColor : blue;
			//--bgColor : var(--swatch-primary);
			//--borderColor : var(--swatch-primary);
			--color : currentColor;
			--borderColor : currentColor;

			margin-right: 0.75em;
			opacity: 0.75;
			&:hover { opacity: 1; }
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
