<!--

	Component to handle image display.
	Acts on element resize via https://www.npmjs.com/package/vue-resize-directive

	Usage:

		<MhImage
			:imageObject="post.acf.image"
			mode="img"
		></MhImage>


	Props:

		:imageObject: 		acf-image-object
		:mode				"img" (default)				takes the height the image needs
							"cover"						css-height needs to be set
							"contain"					css-height needs to be set
		:position			"center center" (default)	sets object-position
		:savePixel			0 (default)					a number the is added due the best size calculation.
														Hilf in folgendem Fall:
														Angenommen ich habe eine Box mit der Breite von 799px und das Best-Size-Image
														wäre dann das 800px breite Bild. Dieses ist allerdings etwas zu unscharft und es
														wäre besser dann zur nächsten Größe zu springen. Dafür hilft die savePixel-Prop:
														Diese setzte ich dann auf 10 und das 800px breite Bild wäre dann zu klein für
														die 799px breite Box und es wird das 1200px breite Bild genommen.

	TODO: 	handle checkSizeBy : height, aktuell wird height verwendet wenn mode=cover, aber so richtig gut ist das nicht
	TODO: 	add lazy loading via IntersectionObserver, https://alligator.io/vuejs/lazy-image/
			function supportsIntersectionObserver() {
				return (
					'IntersectionObserver' in global &&
					'IntersectionObserverEntry' in global &&
					'intersectionRatio' in IntersectionObserverEntry.prototype
				)
			}

	2021-09-23	improvement: resolved dependency of this._ to normal lodash
	2021-07-13	improvement: added loadingAttr prop with 'lazy' as default
	2021-07-13	improvement: added allowLoad prop with true as default to handle loading media on offcanvas views
	2021-07-13	improvement: added mountedDelay prop with 75 as default to prevent loading the smallest image first

	2021-05-30	improvement: changed class-name from "mhImage" to "MhImage"
	2021-05-24	bugfix: computed.bestSize to methods.getBestSize to prevent vue error caused by unhandled side-effects in cumputed property
	2020-12-30	improvement: added v-if="imageObject" to elm
	2020-04-27	improvement: added prop checkSizeBy, default = 'width'
				improvement: added debugInfo fileName. shows the choosen image basename
	2019-12-06	improvement: added prop position which handels the css prop "object-position" (same as background-postion before)
				improvement: added prop savePixel a number that is added to the bestSize calculation.

	2019-11-21	improvement: changed from background-image to real <img /> for google
	2019-11-21	improvement: added alt attr from caption-field (Beschriftung in german wp) if given
	2019-11-21	change: removed prop backgroundPosition

	2019-11-20	improvement: devicePixelRatio als multiplicator für das finden der besten größe eingebaut
	2019-11-20	improvement: debug infos besser gestylt
	2019-07-12	improvement: für gifs wird jetzt immer die full-url ausgegeben
	2019-06-04	improvement: docs korrigiert und erweitert
	2019-06-04	improvement: added mode class to elm
	2019-06-03	improvement: removed scoped attribute for easier styling

-->

<template>
	<div class="MhImage" :class="elmClasses" v-resize:throttle="setElmWidthAndHeight" v-if="hasValidImageObject">
		<div class="MhImage__imageWrapper" :class="imageWrapperClasses" :style="imageWrapperStyles">
			<img class="MhImage__image"
				 :class="imageClasses"
				 :style="imageStyles"
				 :src="imageSrc"
				 :alt="imageAlt"
				 :loading="loadingAttr"
			/>
			<!--
			-->
		</div>
		<div class="MhImage__debugInfos"
			 :data-elmWidth="elmWidth"
			 :data-elmHeight="elmHeight"
			 :data-checkSizeBy="checkSizeBy"
			 :data-savePixel="savePixel"
			 :data-bestSizeName="bestSizeName"
			 :data-fileName="fileName">
			<!--
			<pre>{{imageSizes}}</pre>
			-->
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	// https://www.npmjs.com/package/vue-resize-directive
	import resize from 'vue-resize-directive'
	import { size, cloneDeep, sortBy, filter, last } from 'lodash'
	const  path = require('path')

	export default {
		name: 'MhImage',
		components: {},
		directives: {
			resize,
		},
		props: {
			imageObject: {
				type: [Object, Boolean],
				//required: true,
			},
			mode: {
				type: String,
				default: 'img',
			},
			position: {
				type: String,
				default: 'center center',
			},
			savePixel: {
				type: Number,
				default: 0,
			},
			debug: {
				type: Boolean,
				default: false,
			},
			checkSizeBy: {
				type: String,
				default: 'width',
			},
			allowLoad: {
				type: Boolean,
				default: true,
			},
			loadingAttr: {
				type: String,
				default: 'lazy',
			},
			mountedDelay: {
				type: Number,
				default: 75,
			},
		},
		data() {
			return {
				isMounted    : false,
				imageSizes   : {},
				elmWidth     : null,
				elmHeight    : null,
				imageOpacity : 0,
			}
		},
		watch: {
			imageObject ( to, from ){
				if(to) this.setImageSizes()
			}
		},
		computed: {
			hasValidImageObject(){
				return size( this.imageObject ) > 0 ? true : false
			},
			XXXcheckSizeBy() {
				return 'width'
				/*
				if( this.mode == 'cover' ){
					return 'height'
				}else{
					return 'width'
				}
				*/
			},
			bestSizeName() {
				const bestSize = this.getBestSize()
				return bestSize ? bestSize.name : false
			},
			fileName() {
				return this.imageSrc ? path.basename( this.imageSrc ) : null
			},
			imageWrapperClasses() {
				return 'MhImage__imageWrapper--mode-' + this.mode
			},
			imageWrapperStyles() {
				const width    = this.imageObject.width
				const height   = this.imageObject.height

				let styles = {
					paddingBottom : (height/width) * 100 + '%',
				}

				if( this.mode == 'cover' ){
					styles.paddingBottom = false
				}
				if( this.mode == 'contain' ){
					styles.paddingBottom = false
				}

				return styles
			},
			imageClasses() {
				return 'MhImage__image--mode-' + this.mode
			},
			imageStyles() {
				let styles = {}

				styles.objectPosition = this.position

				return styles
			},
			imageSrc() {
				const bestSize = this.getBestSize()
				const transparentGifData = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='
				let src = bestSize ? bestSize.url : ''

				if( !this.allowLoad || !this.isMounted ) src = transparentGifData

				//if( this.allowLoad ) console.log( this.$options.name, '• src:', src)

				return src
			},
			imageAlt() {
				return this.imageObject.caption ? this.sanitizeString( this.imageObject.caption )  : ''
			},
		},
		methods: {
			elmClasses() {
				return 'MhImage--mode-' + this.mode
			},
			/**
			 *  this function is called after resizing
			 *  to set elmWidth and elmHeight
			 */
			setElmWidthAndHeight( doLog = true ) {
				this.elmWidth = this.$el.clientWidth
				this.elmHeight = this.$el.clientHeight
			},
			/**
			 *  Set sizes, transforms acf-object to a more readable version:
			 *
			 *  sizes: {
    		 *		"thumbnail": "http://ies.local:8080/wp-content/uploads/2018/09/dummy-2160x2880-mermaid-100x100.jpg",
    		 *    	"thumbnail-width": 100,
    		 *    	"thumbnail-height": 100
    		 *  }
			 *
			 * 	sizes: {
			 * 		thumbnail: {
			 * 			width: 100,
			 * 			height: 100
			 * 		}
			 * 	}
			 *
			 */
			setImageSizes() {
				const imageObject = this.imageObject
				let newSizes = {}
				let newSizesArr = []

				if(imageObject){
					const sizes = imageObject.sizes

					for (let elem in sizes) {
						let key   = elem
						let value = sizes[elem]

						// detect if key is a size name, e.g. "thumbnail"
						if( sizes[key+'-width'] ){
							newSizes[key] = {
								url : value,
								width : sizes[key+'-width'],
								height : sizes[key+'-height'],
							}
							newSizesArr.push({
								name : key,
								url : value,
								width : sizes[key+'-width'],
								height : sizes[key+'-height'],
							})
						}
					}

					//this.imageSizes = newSizes
					this.imageSizes = newSizesArr
				}
			},
			sanitizeString( Text ) {
				return Text
					//.toLowerCase()
					//.replace(/[^a-zA-Z0-9]+/g,'-')
					//.replace(/ /g,'-')
					.replace(/"/g, '')
					.replace(/'/g, '')
					//.replace(/[^\w ]+/g,'')
					;
			},
			getBestSize() {
				const debug = this.debug
				const mimeType = this.imageObject ? this.imageObject.mime_type : null
				const devicePixelRatio = window.devicePixelRatio ? window.devicePixelRatio : 1
				let bestSize = null
				const checkSizeBy = this.checkSizeBy
				const savePixel = this.savePixel
				const elm = {
					width : this.elmWidth,
					height : this.elmHeight,
				}

				let sizes1 = cloneDeep( this.imageSizes );
				let	sizes = sortBy(sizes1, checkSizeBy)
				let bigEnoughSizes = filter(sizes, size => size[checkSizeBy] > ( (elm[checkSizeBy] + savePixel) * devicePixelRatio ) );

				// für gifs ist immer "full" die beste größe, da in den
				// anderen (generierten) größen die animation verloren ging
				if( mimeType == 'image/gif' ){
					bigEnoughSizes = filter(sizes, size => size.name === 'full');
				}

				if( bigEnoughSizes.length ){
					bestSize = bigEnoughSizes[0]
				}else{
					bestSize = last( sizes )
				}

				if(debug && bestSize){
					console.log('')
					console.group('bestSize()')
					console.log('elm.width : ', elm.width)
					console.log('devicePixelRatio:', devicePixelRatio)
					console.log('savePixel:', savePixel)
					console.log('sizes:', sizes)
					console.log('bigEnoughSizes:', bigEnoughSizes)
					console.log('bestSize:', bestSize)
					console.log('mimeType:', mimeType)
					console.groupEnd()
				}

				return bestSize
			},
		},
		created() {},
		mounted() {
			// this prevents loading the smallest image first
			/*
			this.$nextTick(()=>{
				this.isMounted = true
				this.setImageSizes()
				this.setElmWidthAndHeight()
				//this.setElmWidthAndHeight()
			})
			*/
			setTimeout(()=>{
				this.isMounted = true
				this.setImageSizes()
				this.setElmWidthAndHeight()
				this.setElmWidthAndHeight()
			}, this.mountedDelay)
		},
	}
</script>

<style lang="less">
	.MhImage {
		position: relative;
	}
	.MhImage__debugInfos {
		position: absolute;
		top: 0; left: 0;
		width: 100%; height: 100%;
		overflow: auto;

		// label with debug infos
		[showborders6] &:before {
			position: absolute;
			top: 0; left: 0;
			content: attr(data-elmWidth) " × " attr(data-elmHeight) "\a" "checkBy: " attr(data-checkSizeBy) "\a" "savePixel: " attr(data-savePixel) "\a" "bestSize: " attr(data-bestSizeName) "\a" "fileName: " attr(data-fileName);
			padding: 0.3em;

			font-family: sans-serif;
			white-space: pre-line;
			color: fade(black, 75);
			font-size: 11px;
			line-height: 1.2em;
			background-color: yellow;
		}

		pre {
			background-color: fade(yellow, 50);
		}
	}

	.MhImage__imageWrapper {
		//background-color: fade( yellow, 50 );

		position: relative;
		width: 100%;
	}
	.MhImage__imageWrapper--mode-cover {
		position: absolute;
		top: 0; bottom: 0;
	}
	.MhImage__imageWrapper--mode-contain {
		position: absolute;
		top: 0; bottom: 0;
	}

	.MhImage__image {
		position: absolute;
		top: 0; left: 0;
		height: 100%;
		width: 100%;
	}
	.MhImage__image--mode-img {
		object-fit: cover;
	}
	.MhImage__image--mode-cover {
		object-fit: cover;
	}
	.MhImage__image--mode-contain {
		object-fit: contain;
	}
</style>
