<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<DgLightboxLayer></DgLightboxLayer>

	2021-09-24	init

-->

<template>
	<div class="DgLightboxLayer" :class="elmClasses" :data-dev-name="'DgLightboxLayer'">
		<div class="DgLightboxLayer__inner">
			<div class="DgLightboxLayer__bg" @click="isActive = false"></div>
			<div class="DgLightboxLayer__nav">
				<DbButton
					@click.native="isActive = false"
					:type="'cross'"
					:isCircle="true"
				></DbButton>
			</div>
			<div class="DgLightboxLayer__content" XXXclick="isActive = false">
				<MhImage class="DgLightboxLayer__image" :imageObject="image" :mode="'contain'"></MhImage>
				<div class="DgLightboxLayer__buWrapper" v-if="bu">
					<DgBu
						:caption="bu"
						:copyright="copyright"
					></DgBu>
				</div>
				<!--
				<div class="DgLightboxLayer__imageWrapper">
				</div>
				-->
			</div>
			<!--
				<div class="DgLightboxLayer__imageWrapper">
				</div>
			<pre name="menubarIsHidden">{{menubarIsHidden}}</pre>
			<pre name="articlePosts">{{articlePosts}}</pre>
			-->
		</div>
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import DbButton from '@/components/molecules/DgButton.vue'
	import MhImage from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhImage/v3/MhImage.vue'
	import DgBu from '@/components/molecules/DbBu.vue'

	export default {
		name: 'DgLightboxLayer',
		components: {
			DbButton,
			MhImage,
			DgBu,
		},
		mixins: [],
		//directives: { dragscroll },
		props: {},
		data(){
			return {
				isActive : false,
				image : null,
				bu : null,
				copyright : null,
			}
		},
		watch: {
			// disable scrolling on html + body
			'isActive': {
				handler: function( to, from ){
					// disable scrolling on html + body
					if( to ) document.documentElement.style.overflow = 'hidden'
					else document.documentElement.style.overflow = ''
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				if( this.isActive ) classes.push( this.$options.name + '--isActive')
				else classes.push( this.$options.name + '--isInActive')

				return classes
			},
		},
		methods: {
			onAfterKeydown( e ){
				if( e.key === 'Escape' ) this.isActive = false
			},
		},
		created(){
			window.addEventListener('keydown', this.onAfterKeydown)
		},
		mounted(){
			EventBus.$on('doOpenLightbox', ( payload, doLog = true )=>{
				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• on doOpenLightbox' )
					console.log('payload:', payload)
					console.groupEnd()
				}

				this.isActive = true
				this.image = this._.get( payload, 'image' )
				this.bu = this._.get( payload, 'bu' )
				this.copyright = this._.get( payload, 'copyright' )
			})
		},
		destroyed(){
   			window.removeEventListener('keydown', this.onAfterKeydown)
			EventBus.$off('doOpenLightbox')
 		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.DgLightboxLayer { // debug
		[showBorders2] & {}
	}
	.DgLightboxLayer { // layout
		position: fixed !important;
		top: 0; left: 0; right: 0; bottom: 0;
		z-index: 100;
		pointer-events: none;
		opacity: 0;

		&--isActive {
			pointer-events: auto;
			opacity: 1;
		}

		&__bg {
			position: absolute;
			top: 0; right: 0; bottom: 0; left: 0;
			background-color: fade( black, 85 );
			z-index: 1;
		}
		&__nav {
			position: absolute;
			top: 0; right: 0;
			z-index: 3;
		}
		&__content {
			position: absolute;
			top: 0; right: 0; bottom: 0; left: 0;
			z-index: 2;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
		}
		&__image {
			//background-color: fade( green, 75 );
			height: 80vh;
			width: 80vw;
		}

		@media @mq[sm] {
			&__image {
				height: 90vh;
				width: calc( 100vw - (var(--app-content-hPadding) * 2) );
			}
		}
	}
	.DgLightboxLayer { // styling
		transition: all 0.25s ease;

		&__nav {
			padding-top: var(--app-gui-vPadding);
			padding-right: var(--app-gui-hPadding);
			padding-bottom: var(--app-gui-vPadding);
			padding-left: var(--app-gui-hPadding);
		}

		&__buWrapper {
			//background-color: fade( green, 50 );
			width: 100%;
			max-width: var(--app-content-maxWidth);
			align-self: center;
			padding: 0 var( --app-content-hPadding);

			color: white;
			text-align: center;
			width: 100%;
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
