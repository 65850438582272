<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<Grid></Grid>

	2021-09-24	init

-->

<template>
	<div class="DgGrid" :class="elmClasses">
		<div class="DgGrid__inner">
			<slot></slot>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'

	export default {
		name: 'DgGrid',
		components: {},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
			countColums: {
				type     : [Number],
				default  : 1,
				required : false,
			},
		},
		data(){
			return {}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				classes.push( this.$options.name + '--cols-' + this.countColums )

				return classes
			},
			/*
			innerElmStyles(){
				let styles = {}

				styles.gridTemplateColumns = 'repeat('+this.countColums+', 1fr)'

				return styles
			},
			*/
		},
		methods: {},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.DgGrid { // debug
		[showBorders2] & {}
	}
	.DgGrid { // layout
		&--cols-2 &__inner { grid-template-columns : repeat(2, 1fr); }
		&--cols-1 &__inner { grid-template-columns : repeat(1, 1fr); }

		&__inner {
			display: grid;
			gap: var(--app-gridGap);

			& > * {
				min-width: 0; // prevent grid blow out
			}
		}

		@media @mq[sm] {
			&__inner { gap: 0rem; }
			&--cols-2 &__inner { grid-template-columns : repeat(1, 1fr); }
			&--cols-1 &__inner { grid-template-columns : repeat(1, 1fr); }
		}
	}
	.DgGrid { // styling
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
