<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<DgComponentNotFound></DgComponentNotFound>

	2021-09-24	init

-->

<template>
	<div class="DgComponentNotFound contentModule" :class="elmClasses" :data-dev-name="'DgComponentNotFound'">
		<div class="DgComponentNotFound__inner contentModule__inner" :class="innerElmClasses">
			<div class="DgComponentNotFound__content contentModule__content" :class="contentElmClasses">
				<strong>DgComponentNotFound</strong><br/>
				<br/>
				<pre name="$attrs">{{$attrs}}</pre>
			</div>
		</div>
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import dgContentModuleMixin from '@/mixins/dgContentmodule.mixin'

	export default {
		name: 'DgComponentNotFound',
		components: {},
		mixins: [ dgContentModuleMixin ],
		props: {
			//imageObject: [Object, Boolean],
			//DgComponentNotFound: [String],
			/*
			size: {
				type     : [String],
				default  : 'default',
				required : false,
			},
			*/
		},
		data(){
			return {}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--size-' + this.size )

				return classes
			},
			innerElmClasses(){
				let classes = []

				//classes.push( 'font--size' + this._.upperFirst( this.fontSize ) )

				return classes
			},
			contentElmClasses(){
				let classes = []

				return classes
			},
		},
		methods: {},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.DgComponentNotFound { // debug
		[showBorders2] & {}
	}
	.DgComponentNotFound { // layout
		&__inner {}
		&__content {}
	}
	.DgComponentNotFound { // styling
		padding: 1em;
		background-color: yellow;
		color: black;
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
