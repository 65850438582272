<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<DgStickyWrapper></DgStickyWrapper>

	2021-09-24	init

-->

<template>
	<div class="DgStickyWrapper" :class="elmClasses" :data-dev-name="'DgStickyWrapper'">
		<div class="DgStickyWrapper__inner">
			<slot></slot>
		</div>
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import dgContentModuleMixin from '@/mixins/dgContentmodule.mixin'

	export default {
		name: 'DgStickyWrapper',
		components: {},
		mixins: [ dgContentModuleMixin ],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
			contentModules: {
				type     : [Array, Boolean],
				default  : ()=>{ return []},
				required : false,
			},
		},
		data(){
			return {}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
		},
		methods: {
			someFunction( doLog = true ){
				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• handleScroll()' )
					//console.log('key:', value)
					console.groupEnd()
				}
			},
		},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.DgStickyWrapper { // debug
		[showBorders4] & {
			background-color: fade( yellow, 50 );
		}
	}
	.DgStickyWrapper { // layout
		position: sticky !important; // overwrite debug position: relative;
		top: 0;

		&__inner {
			display: flex;
			flex-direction: column
		}
	}
	.DgStickyWrapper { // styling

	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
