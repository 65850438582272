<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<DgMainNavLayer></DgMainNavLayer>

	2021-09-24	init

-->

<template>
	<div class="DgMainNavLayer" :class="elmClasses" :data-dev-name="'DgMainNavLayer'">
		<div class="DgMainNavLayer__inner">

			<div class="DgMainNavLayer__bg" @click="isActive = false"></div>

			<div class="DgMainNavLayer__sidebar animated">
				<div class="DgMainNavLayer__sidebarHeader">
					<a class="DgMainNavLayer__sidebarHeaderLeft" @click="doScrollTop">
						<div class="DgMainNavLayer__menuOverTitle hideIfEmpty" v-html="menuOverTitle"></div>
						<div class="DgMainNavLayer__menuTitle hyphenate hideIfEmpty" v-html="menuTitle"></div>
					</a>
					<div class="DgMainNavLayer__sidebarHeaderRight">
						<!--
							<DgButton :text="'DE'" :isCircle="true"></DgButton>
						-->
						<DgButton :type="'cross'" :isCircle="true" @click.native="isActive = false"></DgButton>
					</div>
				</div>
				<div class="DgMainNavLayer__sidebarBody customScrollbars">
					<!--
					<pre name="chapterLinks">{{chapterLinks}}</pre>
					<hr/>
					-->
					<ul class="DgMainNavLayer__linkList DgMainNavLayer__linkList--chapterItems">
						<li class="DgMainNavLayer__linkListItem DgMainNavLayer__linkListItem--chapterItem" v-for="(item, index) in chapterLinks" :key="index">
							<a class="DgMainNavLayer__linkListLink DgMainNavLayer__linkListLink--chapterItem"
							   @click="onClickListLink"
							   :href="item.url"
							   :target="item.target">
								<span class="DgMainNavLayer__linkListLinkIndex">{{index+1}}</span>
								<span v-html="item.title"></span>
							</a>
						</li>
					</ul>

					<!--
					<pre name="additionalLinks">{{additionalLinks}}</pre>
					<hr/>
					-->
					<br/>

					<ul class="DgMainNavLayer__linkList DgMainNavLayer__linkList--additionalItems">
						<li class="DgMainNavLayer__linkListItem DgMainNavLayer__linkListItem--additionalItem" v-for="(item, index) in additionalLinks" :key="index">
							<a class="DgMainNavLayer__linkListLink DgMainNavLayer__linkListLink--additionalItem"
							   @click="onClickListLink"
							   :href="item.url"
							   :target="item.target">
								<!--
								-->
								<MhIcon class="DgMainNavLayer__linkListLinkIndex" :type="'arrow-right'"></MhIcon>
								<span v-html="item.title"></span>
							</a>
						</li>
					</ul>
				</div>
				<div class="DgMainNavLayer__sidebarFooter">
					<DgSocialBtns></DgSocialBtns>
				</div>
			</div>

			<!--
			<pre name="menuItems">{{menuItems}}</pre>
			-->
		</div>
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import MhIcon from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhIcon/v2/MhIcon.vue'
	import DgButton from '@/components/molecules/DgButton.vue'
	import DgSocialBtns from '@/components/molecules/DgSocialBtns.vue'

	export default {
		name: 'DgMainNavLayer',
		components: {
			MhIcon,
			DgButton,
			DgSocialBtns,
		},
		mixins: [],
		props: {
			menuBgColor: {
				type     : [String],
				default  : '',
				required : false,
			},
			menuColor: {
				type     : [String],
				default  : '',
				required : false,
			},
			menuOverlayBgColor: {
				type     : [String],
				default  : '',
				required : false,
			},
			menuOverlayOpacity: {
				type     : [String],
				default  : '',
				required : false,
			},
			menuOverTitle: {
				type     : [String],
				default  : '',
				required : false,
			},
			menuTitle: {
				type     : [String],
				default  : '',
				required : false,
			},
			chapterLinks: {
				type     : [Array],
				default  : ()=>{ return [] },
				required : false,
			},
			additionalLinks: {
				type     : [Array],
				default  : ()=>{ return [] },
				required : false,
			},
		},
		data(){
			return {
				isActive : false,
			}
		},
		watch: {
			// disable scrolling on html + body
			// 2021-11-04  ist wieder raus. führt einerseits dazu,
			// dass das layout bei angeschlossener maus unter macOS springt
			// und andererseits, dass nicht zu den ankern gescrollt wird
			'isActive': {
				handler: function( to, from ){
					// disable scrolling on html + body
					//if( to ) document.documentElement.style.overflow = 'hidden'
					//else document.documentElement.style.overflow = ''
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				if( this.isActive ) classes.push( this.$options.name + '--isActive')
				else classes.push( this.$options.name + '--hasHiddenSidebar')

				return classes
			},
		},
		methods: {
			someFunction( doLog = true ){
				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• handleScroll()' )
					//console.log('key:', value)
					console.groupEnd()
				}
			},
			onAfterKeydown( e ){
				if( e.key === 'Escape' ) this.isActive = false
			},
			onClickListLink(){
				this.isActive = false
			},
			doScrollTop(){
				this.isActive = false;
				EventBus.$emit('doScrollTop')
			},
			setCssProperties( doLog = false ){
				if( this.menuBgColor ) this.$el.style.setProperty( '--app-gui-sidebar-bgColor', this.menuBgColor )
				if( this.menuColor ) this.$el.style.setProperty( '--app-gui-sidebar-color', this.menuColor )
				if( this.menuOverlayBgColor ) this.$el.style.setProperty( '--app-gui-sidebar-overlay-bgColor', this.menuOverlayBgColor )
				if( this.menuOverlayOpacity ) this.$el.style.setProperty( '--app-gui-sidebar-overlay-opacity', this.menuOverlayOpacity )
			},
		},
		created(){
			window.addEventListener('keydown', this.onAfterKeydown)
		},
		mounted(){
			EventBus.$on('doOpenMainMenu', ()=>{
				this.isActive = true
			})

			this.setCssProperties()
		},
		destroyed(){
   			window.removeEventListener('keydown', this.onAfterKeydown)
			EventBus.$off('doOpenMainMenu')
 		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.DgMainNavLayer { // debug
		[showBorders2] & {}
		//background-color: fade(brown, 75);
		//background-color: lighten(black, 100);

		//&__sidebar { background-color: fade(brown, 15);	}
		//&__sidebar > * { outline: 1px solid red; }
	}
	.DgMainNavLayer { // vars
		--width: 600px;
		--transitionDuration: 0.25s;
		/*
		--fontSize-l : 32px;
		--lineHeight-l : 1.31em;
		--fontSize-m : 22px;
		--lineHeight-m : 1.2em;
		--fontSize-s : 14px;
		--lineHeight-s : 1.28em;
		*/
	}
	.DgMainNavLayer { // layout
		position: fixed !important;
		top: 0; left: 0; right: 0; bottom: 0;
		z-index: 200;
		pointer-events: none;

		&__inner {
			display: flex;
			height: 100%;
			justify-content: flex-end;
		}
		&__bg {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
		}

		&__sidebar {
			position: relative;
			display: flex;
			flex-direction: column;
			height: 100%;
			width: var(--width);
			pointer-events: auto;
		}
		&__sidebarHeader {
			position: relative;
			display: flex;
			flex-shrink: 0;

			&Left { flex-grow: 1; flex-shrink: 1; }
			&Right { display: flex; flex-shrink: 0; }
		}
		&__sidebarBody {
			position: relative;
			flex-grow: 1;
			overflow-x: hidden;
			overflow-y: auto;
		}
		&__sidebarFooter {
			position: relative;
			flex-shrink: 0;
			display: flex;
			//display: none;
		}

		&__linkListLink {
			display: flex;
			//align-items: center;
		}
	}
	.DgMainNavLayer { // styling
		&__bg {
			transition: all 0.25s var(--transitionDuration);
			background-color: var(--app-gui-sidebar-overlay-bgColor );
			opacity: 0;
		}
		&--isActive &__bg {
			opacity: var(--app-gui-sidebar-overlay-opacity);
			pointer-events: auto;
		}

		&__sidebar {
			transition: all 0.25s var(--transitionDuration);
			background-color       : transparent;
			background-color       : var(--app-gui-sidebar-bgColor);
			//-webkit-backdrop-filter: blur(65px);
			 //       backdrop-filter: blur(65px);
		}
		&__sidebar::before { // shadow left
			position: absolute;
			left: 0; bottom: 0; top: 0;
			width: 1em;
			transition: all 0.25s var(--transitionDuration);
			transform: translateX(-95%);
			pointer-events: none;
			content: "";
			background: linear-gradient(180deg, var(--app-gui-sidebar-bgColor) 0%, rgba(0,0,0,0) 100%);
			background: linear-gradient(-90deg, fade(black, 50) 0%, rgba(0,0,0,0) 100%);
			opacity: 0;
			z-index: 2;
		}
		&--hasHiddenSidebar &__sidebar { transform: translateX(100%); }
		&--isActive &__sidebar { transform: translateX(0); }
		&--isActive &__sidebar::before { opacity: 0.5; }

		&__sidebarHeader {
			padding-left: var(--app-gui-hPadding);
			padding-right: var(--app-gui-hPadding);
			padding-top: var(--app-gui-vPadding);
			padding-bottom: 1em;

			&Left {
				color : var(--app-gui-sidebar-color);
			}
			.DgButton {
				margin-left: var(--app-button-gap);
			}
		}
		&__sidebarBody {
			padding-top: 1em;
			padding-left: var(--app-gui-hPadding);
			padding-right: var(--app-gui-hPadding);
			padding-bottom: 2em;

			color : var(--app-gui-sidebar-color);
			font-size: var(--app-gui-fontSize-m);
			line-height: var(--app-gui-lineHeight-m);
		}
		&__sidebarFooter {
			padding-top: 1em;
			padding-left: var(--app-gui-hPadding);
			padding-right: var(--app-gui-hPadding);
			padding-bottom: var(--app-gui-vPadding);

			color : var(--app-gui-sidebar-color);
		}
		&__sidebarFooter::before { // shadow above
			position: absolute;
			left: 0; top: 0; right: 0;
			height: 3em;
			transform: translateY(-100%);
			pointer-events: none;
			content: "";
			//background: linear-gradient(180deg, var(--app-gui-sidebar-bgColor) 0%, rgba(0,0,0,0) 100%);
			background: linear-gradient(0deg, red 0%, rgba(0,0,0,0) 100%);
			background: linear-gradient(0deg, var(--app-gui-sidebar-bgColor) 0%, rgba(0,0,0,0) 100%);
			z-index: 2;
		}

		&__menuOverTitle {
			font-size: var(--app-gui-fontSize-s);
			line-height: var(--app-gui-lineHeight-s);
			font-weight: 500;
			padding-bottom: 0.5em;
		}
		&__menuTitle {
			font-size: var(--app-gui-fontSize-l);
			line-height: var(--app-gui-lineHeight-l);
			font-weight: 700;
			//padding-bottom: 0.5em;
		}

		&__linkList {
			list-style: none;
			padding: 0; margin: 0;
		}
		&__linkListLink {
			transition: all 0.1s ease;
			color: inherit;
			//min-height: 35px;

			span { transition: inherit; }
			span:last-child { opacity: 0.65; }
			&:hover span:last-child { opacity: 1; }
		}
		&__linkListLink--chapterItem {
			display: flex;
			border-top: 0.1em solid;
			padding: 0.25em 0;
			font-weight: bold;
		}
		&__linkListLink--additionalItem {
			padding: 0.1em 0;
			font-weight: 500;
		}
		&__linkListItem--chapterItem:last-child &__linkListLink--chapterItem {
			border-bottom: 0.1em solid;
		}
		&__linkListLinkIndex {
			padding-right: 0.65em;
			min-width: 2em;
			height: var(--app-gui-lineHeight-m);
			display: inline-flex;
			flex-shrink: 0;
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
