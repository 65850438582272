<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<DgHeadline
			:fontSize="'xxl'"
			:textAlign="'left'"
			:hasLineAbove="false"
			:title="'My amazing headline!'"
		></DgHeadline>

	2022-02-23	feature: added prop textAlign
	2021-09-24	init

-->

<template>
	<div class="DgHeadline contentModule" :class="elmClasses" :data-dev-name="'DgHeadline • ' + fontSize + ' • ' + textAlign">
		<div class="DgHeadline__inner contentModule__inner" :class="innerElmClasses">
			<div class="DgHeadline__content contentModule__content" :class="contentElmClasses">
				<div class="DgHeadline__lineAbove" v-if="title && hasLineAbove"></div>
				<div class="DgHeadline__title richText noWordWrap hideIfEmpty "
					:class="canHyphenate ? 'hyphenate' : ''" v-html="_title"
				></div>
			</div>
		</div>

		<!--
		<DgEditComponentInner></DgEditComponentInner>
		-->
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import dgContentModuleMixin from '@/mixins/dgContentmodule.mixin'
	import nl2br from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/helper/nl2br.js'
	//import DgEditComponentInner from '@/components/editor/DgEditComponentInner.vue'

	export default {
		name: 'DgHeadline',
		components: {
			//DgEditComponentInner,
		},
		mixins: [ dgContentModuleMixin ],
		props: {
			fontSize: {
				type     : [String],
				default  : 'xxl',
				required : false,
			},
			textAlign: {
				type     : [String],
				default  : 'left',
				required : false,
			},
			hasLineAbove: {
				type     : [Boolean],
				default  : false,
				required : false,
			},
			canHyphenate: {
				type     : [Boolean],
				default  : true,
				required : false,
			},
			title: {
				type     : [String],
				default  : '',
				required : false,
			},
		},
		data(){
			return {}
		},
		watch: {},
		computed: {
			_title(){
				let title = this._.trim( this.title )

				return nl2br( title )
			},
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				classes.push( this.$options.name + '--textAlign-' + this.textAlign )

				return classes
			},
			innerElmClasses(){
				let classes = []

				//classes.push( 'font--size' + this._.upperFirst( this.fontSize ) )

				return classes
			},
			contentElmClasses(){
				let classes = []

				classes.push( 'fontStyle--' + this.fontSize )

				return classes
			},
		},
		methods: {},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.DgHeadline { // debug
		[showBorders2] & {}
	}
	.DgHeadline { // layout
		// grid
		.DgGrid--cols-1 &__content { grid-column: 3 / span 8; }
		.DgGrid--cols-2 &__content { grid-column: 1 / span 6; }

		// grid
		@media @mq[sm] {
			.DgGrid--cols-1 &__content { grid-column: 1 / span 12; }
			.DgGrid--cols-2 &__content { grid-column: 1 / span 12; }
		}

		&__title {
			width: 100%;
		}
	}
	.DgHeadline { // styling
		&--textAlign-left &__content { align-items: flex-start; text-align: left; }
		&--textAlign-center &__content { align-items: center; text-align: center; }
		&--textAlign-right &__content { align-items: flex-end; text-align: right; }

		&__lineAbove {
			background-color: currentColor;
			height: 0.15em;
			width: 2em;
			margin-bottom: 0.22em;
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
