<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<DgMainFooter></DgMainFooter>

	2021-09-24	init

-->

<template>
	<div class="DgMainFooter" :class="elmClasses" :data-dev-name="'DgMainFooter'">
		<div class="DgMainFooter__inner">
			<!-- logo + footer menu row-->
			<div class="DgMainFooter__row DgMainFooter__row--footerMenu">
				<div class="DgMainFooter__rowInner">
					<div class="DgMainFooter__box--logo">
						<div v-html="address"></div>
						<!--
						<p>
							<strong>
								I I I I <br/>
								Kunst- und Kulturstiftung<br/>
								Opelvillen Rüsselsheim<br/>
							</strong>
							<br/>
							Ludwig-Dörfler-Allee 9<br/>
							65428 Rüsselsheim<br/>
							<br/>
							Telefon: 06142 835 907<br/>
							Mail: <a href="mailto:termine@opelvillen.de">termine@opelvillen.de</a><br/>
							<br/>
							<a class="mailjet-openWidget" data-token="40281af3a6e1a37c2ad0649f04abe563">Newsletter abonnieren!</a>
							<br/>
							<br/>
						</p>
						-->
						<br />
						<DgSocialBtns></DgSocialBtns>
					</div>
					<div class="DgMainFooter__box--linkGroups" v-if="menu">
						<div class="DgMainFooter__box--linkGroup" v-for="(group, index) in menu" :key="'g'+index">
							<h5 v-html="group.title"></h5><br/>
							<p>
								<a
									v-for="(link, index) in group.links"
									:key="'l'+index"
									:href="link.href"
									:target="link.isTargetBlank ? '_blank' : ''"
									v-html="link.title"
								></a>
							</p>
						</div>
					</div>
				</div>
				<!--
				<pre name="menu">{{menu}}</pre>
				-->
			</div>
		</div>
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'

	import DgSection from '@/components/organisms/DgSection.vue'
	import DgGrid from '@/components/organisms/DgGrid.vue'
	import DgColumn from '@/components/organisms/DgColumn.vue'
	import DgSocialBtns from '@/components/molecules/DgSocialBtns.vue'

	export default {
		name: 'DgMainFooter',
		components: {
			DgSection,
			DgGrid,
			DgColumn,
			DgSocialBtns,
		},
		mixins: [],
		props: {
			menuBgColor: {
				type     : [String],
				default  : '',
				required : false,
			},
			menuColor: {
				type     : [String],
				default  : '',
				required : false,
			},
			address: {
				type     : [String],
				default  : '',
				required : false,
			},
			menu: {
				type     : [Array, Object, Boolean],
				default  : ()=>{ return [] },
				required : false,
			},
		},
		data(){
			return {}
		},
		watch: {},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
		},
		methods: {
			setCssProperties( doLog = false ){
				if( this.menuBgColor ) this.$el.style.setProperty( '--app-gui-sidebar-bgColor', this.menuBgColor )
				if( this.menuColor ) this.$el.style.setProperty( '--app-gui-sidebar-color', this.menuColor )
			}
		},
		created(){},
		mounted(){
			this.setCssProperties()
		},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.DgMainFooter { // debug
		[showBorders3] & {
			&__box--logo { background-color: fade( red, 25 ); }
			&__box--linkGroups { background-color: fade( green, 25 ); }
		}
		[showBorders4] & {
			.label( attr(data-dev-name), fade(red, 75 ) );

			&__row { outline: 1px solid red; }
			&__rowInner { outline: 1px dotted red; }

			&__supporterLogos { outline: 1px solid red; }
			&__supporterLogos .image { outline: 1px dotted red; }
		}
	}
	.DgMainFooter { // vars
		--vPadding : 2em;

		@media @mq[md] {
			--vPadding : 1.75em;
		}
	}
	.DgMainFooter { // layout
		position: relative;

		&__inner {
			display: flex;
			flex-direction: column;
		}
		&__row {
			display: flex;
			flex-direction: column;
		}
		&__rowInner{
			align-self: center;
			width: 100%;
			max-width: var(--app-content-maxWidth);
			padding-left: var(--app-content-hPadding);
			padding-right: var(--app-content-hPadding);
		}

		&__row--footerMenu &__rowInner {
			display: flex;
			flex-wrap: wrap;
		}

		&__box--logo {
			flex-grow: 1;
		}
		&__box--linkGroups {
			display: flex;
			flex-wrap: wrap;
		}
		&__box--linkGroups a {
			display: block;
		}

		@media @mq[dt] {}
		@media @mq[sm] {
			&__box--logo { width: 100%; }
			&__box--linkGroups { width: 100%; }
			&__box--linkGroup { width: 50%; }
		}
	}
	.DgMainFooter { // styling
		font-size: var(--app-gui-fontSize-s);
		line-height: var(--app-gui-lineHeight-s);

		&__row--footerMenu {
			color: var(--app-gui-sidebar-color);
			background-color: var(--app-gui-sidebar-bgColor);
		}
		&__row--footerMenu &__rowInner {
			padding-top: var(--vPadding);
			padding-bottom: 10em;
		}

		&__box--logo { padding-bottom: 3em; }
		&__box--linkGroup { padding-right: 1em; padding-bottom: 2em; }
		&__box--linkGroup h5 { font-weight: bold; }
		&__box--logo p a,
		&__box--linkGroup p a {
			transition: all 0.1s ease;
			color: inherit;

			opacity: 0.65;
			&:hover { opacity: 1; }
		}

	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
