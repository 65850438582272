<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<DgReaderLayer>
			content goes here
		</DgReaderLayer>

	2021-09-24	init

-->

<template>
	<div class="DgReaderLayer" :class="elmClasses">
		<div class="DgReaderLayer__bg"></div>
		<div class="DgReaderLayer__inner" :class="isOpen ? 'animated bounceInUp' : 'animated fadeOutDownBig'">
			<div class="DgReaderLayer__scroller hideScrollbars" ref="scroller" @click="onClickBg">
				<div class="DgReaderLayer__layoutWrapper" ref="layoutWrapper">
					<div class="DgReaderLayer__closeBtnRow">
						<DgButton class="DgReaderLayer__closeBtn"
							:type="'cross'"
							:isCircle="true"
							@click.native="doCloseReader"
						></DgButton>
					</div>
					<slot></slot>
				</div>
			</div>
		</div>
		<div class="DgReaderLayer__debug">
			<label>canShow <input v-model="canShow" type="checkbox" /></label><br/>
			<!--
			<label>isReady <input v-model="isReady" type="checkbox" /></label><br/>
			<label>isLoading <input v-model="isLoading" type="checkbox" /></label><br/>
			-->
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'
	//import MhIcon from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhIcon/v2/MhIcon.vue'
	import DgButton from '@/components/molecules/DgButton.vue'

	export default {
		name: 'DgReaderLayer',
		components: {
			DgButton,
		},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
			isOpen: {
				type     : [Boolean],
				default  : false,
				required : false,
			},
		},
		data(){
			return {
				canShow : false,
				isMounted : false,
				//isReady : false,
				//isLoading : false,
			}
		},
		watch: {
			isOpen: {
				handler: function( to, from ) {
					//console.log('readerIsOpen:', from, to)
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				if( this.isOpen ) classes.push( this.$options.name + '--isOpen')
				else classes.push( this.$options.name + '--isClosed')

				//if( this.isMounted ) classes.push( this.$options.name + '--isMounted')

				return classes
			},
		},
		methods: {
			onClickBg( e, doLog = true ){
				const elm = e.target
				const isValidElm = elm.classList.contains('DgReaderLayer__scroller')

				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• onClickBg( e )' )
					console.log('e:', e)
					console.log('elm:', elm)
					console.log('isValidElm:', isValidElm)
					console.groupEnd()
				}

				if( isValidElm ) this.doCloseReader()
			},
			onAfterKeydown( e ){
				if( e.key === 'Escape' ){
					this.doCloseReader()
				}
			},
			doOpenReader(){
				/*
				const scrollerEl = this._.get( this.$refs, 'scroller' )
				const scrollerScrollTop = scrollerEl ? scrollerEl.scrollTop : undefined
				console.log( scrollerEl, scrollerScrollTop )
				*/

				this.canShow = true
			},
			doCloseReader(){
				const scrollerEl = this._.get( this.$refs, 'scroller' )
				/*
				const scrollerScrollTop = scrollerEl ? scrollerEl.scrollTop : undefined
				console.log( scrollerEl, scrollerScrollTop )
				*/

				if( this.$route.query.article ) this.$router.push({ query: {} })
				this.canShow = false

				setTimeout(()=>{
					if( scrollerEl ) scrollerEl.scrollTop = 0
				}, 250)
			},
		},
		created(){
			window.addEventListener('keydown', this.onAfterKeydown)
		},
		mounted(){
			/*
			const scrollerEl = this._.get( this.$refs, 'scroller' )
			const scrollerScrollTop = scrollerEl ? scrollerEl.scrollTop : undefined
			console.log( scrollerEl, scrollerScrollTop )
			*/

			setTimeout(()=>{
				//console.log( scrollerEl, scrollerScrollTop )
				//this.isMounted = true
			}, 500)

			/*
			*/
		},
		destroyed(){
   			window.removeEventListener('keydown', this.onAfterKeydown)
 		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.DgReaderLayer { // debug
		[showBorders2] & {}
		//background-color: fade( red, 50 );
	}
	.DgReaderLayer { // vars
		--app-reader-closeBtnRow-height : 5rem;
		--readerBgColor: fade( black, 75 );
		--readerTransition: all 0.25s ease;
		--paddingTop : calc( var(--app-gui-vPadding) + var(--app-button-height) * 0.5 );

		@media @mq[md] {
			--app-reader-closeBtnRow-height: 0em;
			--paddingTop : 0em;
		}
	}
	.DgReaderLayer { // layout
		position: fixed;
		top: 0; left: 0; right: 0; bottom: 0;
		z-index: 100;
		pointer-events: none;

		//visibility: hidden;
		//&--isMounted { visibility: visible; }

		&__bg {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
		}
		&__inner { pointer-events: auto; }
		&__scroller {
			position: relative;
			width: 100%;
			height: 100vh;
			overflow-x: hidden;
			overflow-y: auto;
			z-index: 2;
		}
		&__layoutWrapper {
			margin: 0 auto;
			max-width: var(--app-content-maxWidth);
			min-height: 100vh;
			display: flex;
			flex-direction: column;
		}
		&__layoutWrapper > * {
			//margin-top: 2em;
			background-color: white;
			flex-grow: 1;
			//padding-bottom: 10em;
		}

		&__closeBtnRow {
			position: relative;
			position: sticky;
			z-index: 3;
			flex-grow: 0;
		}
		&__closeBtn {
			position: absolute;
			top: 0; right: 0;
			pointer-events: auto;
		}

		&__debug {
			position: absolute;
			bottom: 3em;
			left: 1em;
			z-index: 10;
			pointer-events: auto;
			display: none;
		}

		@media @mq[dt] {
			&__closeBtnRow {
				top: calc( var(--app-gui-vPadding) + var(--app-button-height) * 0.5 );
			}
			&__closeBtn {
				margin-top: calc( var(--app-button-height) * -0.5 );
				margin-right: calc( var(--app-button-width) * -0.5 );
			}
		}
		@media @mq[md] {
			&__closeBtnRow {
				top: var(--app-gui-vPadding);
				top: 0.5rem;
				margin-bottom: -18px;
			}
			&__closeBtn {
				//margin-top: calc( var(--app-button-height) * -0.5 );
				margin-right: var(--app-gui-hPadding);
				margin-right: 0.5rem;
			}
		}
	}
	.DgReaderLayer { // styling
		&__bg {
			background-color: var(--readerBgColor);
			transition: var(--readerTransition);
			opacity: 0;
		}
		&--isOpen &__bg { opacity: 1; }

		&__inner { transition: var(--readerTransition); }
		//&--isClosed &__inner { transform: translateY(100%); }
		//&--isOpen &__inner { transform: translateY(0%); }
		&__layoutWrapper {
			padding-top: var(--paddingTop);
		}

		&__debug {
			background-color: fade(yellow, 75);
			padding: 1em;
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
