<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<MhStickyHeader>
			<div class="flex flex--spaceBetween">
				<span>MhStickyHeader slot</span>
				<button>Menu</button>
			</div>
		</MhStickyHeader>

	Works only on window scroll and with position sticky.
	The Elm Height is used as hidden threshold.

	TODO: Add custom scroll element prop
	TODO: Add custom threshold prop
	TODO: Show menubar if complete scroll down ( body height == scroll down )

	2021-11-12	improvement: now emit stickyHeaderIsHidden only when changed
	2021-10-21	bugfix: pointer-events:auto greift jetzt nur für den slot content
	2021-10-15	bugfix: show wrong hide on safari on page top and overscroll
	2021-10-11	improvement: added position:fixed prop + use margin-bottom as spacer-gap
	2021-10-06	improvement: added event emit 'stickyHeaderIsHidden'
	2021-06-20	init

-->

<template>
	<div class="MhStickyHeader" :class="elmClasses" :style="elmStyles">
		<div class="MhStickyHeader__menubar" ref="slotWrapperElm" :style="slotWrapperElmStyles">
			<slot></slot>
		</div>

		<div class="MhStickyHeader__debug">
			<!--
			hideBelow : {{hideBelow}}<br/>
			-->
			position : {{position}}<br/>
			scrollTop : {{scrollTop}}<br/>
			scrollDirection : {{scrollDirection}}<br/>
			slotWrapperElmHeight : {{slotWrapperElmHeight}}<br/>
			stickyHeaderIsHidden : {{stickyHeaderIsHidden}}<br/>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	//import DevInfos from '@/components/DevInfos.vue'
	import EventBus from '@/helper/EventBus.js'
	import { throttle } from 'lodash'

	export default {
		name: 'MhStickyHeader',
		components: {},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
			position: {
				type     : [String],
				default  : 'sticky', // sticky | fixed
				required : false,
			},
			zIndex: {
				type     : [Number, String],
				default  : 10,
				required : false,
			}
			/*
			hideBelow: { // needs to be pixel value!
				type     : [Number, String],
				default  : 0,
				required : false,
			},
			*/
		},
		data(){
			return {
				isReady              : false,
				isVisible            : undefined,
				scrollTop            : undefined,
				scrollDirection      : undefined,
				slotWrapperElmHeight : undefined,
			}
		},
		watch: {
			stickyHeaderIsHidden: {
				handler: function( to, from ){
					if( to != this.isVisible ){
						EventBus.$emit('stickyHeaderIsHidden', to)
						this.isVisible = to
					}
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				if( this.stickyHeaderIsHidden ) classes.push( this.$options.name + '--stickyHeaderIsHidden')
				else classes.push( this.$options.name + '--menubarIsVisible')

				if( this.isReady ) classes.push( this.$options.name + '--isReady')
				else classes.push( this.$options.name + '--isNotReady')

				return classes
			},
			elmStyles(){
				let styles = {}

				if( 'sticky' === this.position ){
					styles.position =  this.position
					styles.zIndex = this.zIndex
				}
				if( 'fixed' === this.position ){
					styles.marginBottom = this.slotWrapperElmHeight + 'px'
				}

				return styles
			},
			slotWrapperElmStyles(){
				let styles = {}

				if( 'fixed' === this.position ){
					styles.position =  this.position
					styles.zIndex = this.zIndex
				}

				return styles
			},
			stickyHeaderIsHidden(){
				//const hideBelow = parseInt( this.hideBelow )
				let isHidden

				isHidden = this.scrollDirection === 'down' ? true : false
				//isHidden = this.scrollTop > hideBelow ? true : isHidden
				isHidden = this.scrollTop < 1 ? false : isHidden

				return isHidden
			},
		},
		methods: {
			getElmHeight( el ) {
				// taken from https://stackoverflow.com/a/23749355/7899788
				// Get the DOM Node if you pass in a string
				el = (typeof el === 'string') ? document.querySelector(el) : el

				const styles = window.getComputedStyle( el )
				const margin = parseFloat( styles['marginTop'] ) +
						       parseFloat( styles['marginBottom'] )

				return Math.ceil( el.offsetHeight + margin )
			},
			setSlotWrapperElmHeight(){
				const slotWrapperElmHeight = this.$refs.slotWrapperElm ? this.$refs.slotWrapperElm.offsetHeight : undefined
				this.slotWrapperElmHeight = slotWrapperElmHeight
			},
			setScrollVars( doLog = false ){
				const elm = document.querySelector('html')
				const scrollTop = elm.scrollTop
				const scrollDirection = scrollTop > this.scrollTop ? 'down' : 'up'

				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name + ' • handleScroll() • ' + scrollDirection + ' • ' + scrollTop )
					console.log('elm:', elm)
					console.log('scrollTop:', scrollTop)
					console.log('scrollDirection:', scrollDirection)
					console.groupEnd()
				}

				this.scrollDirection = scrollDirection
				this.scrollTop = scrollTop
			},
			// thats the right way to throttle eventListener
			// with ability to remove listener on destroy
			// taken from https://stackoverflow.com/a/42347649
			throttledOnResize: throttle( function( e ){
				this.setSlotWrapperElmHeight()
			}, 150),
			throttledOnScroll: throttle( function( e ){
				this.setScrollVars()
			}, 150),
		},
		created(){
			window.addEventListener('scroll', this.throttledOnScroll )
			window.addEventListener('resize', this.throttledOnResize )
		},
		mounted( doLog = false ){
			const elm = this.$el

			this.scrollTop = 0

			// delay
			setTimeout(()=>{
				this.isReady = true
				this.setSlotWrapperElmHeight()
			}, 50)

			// groupCollapsed group
			if( doLog ){
				console.groupCollapsed( this.$options.name, '• mounted()' )
				console.log('elm:', elm)
				console.groupEnd()
			}
		},
		destroyed(){
   			window.removeEventListener('scroll', this.throttledOnScroll )
   			window.removeEventListener('resize', this.throttledOnResize)
 		},
	}
</script>

<style lang="less">
	/*
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";
	*/

	.MhStickyHeader {
		//&--menubarIsVisible { background-color: fade( green, 85 ); }
		//&--stickyHeaderIsHidden { background-color: fade( red, 85 ); }

		//&__menubar { outline: 1px solid fade( black, 75 ); }

		&__debug {
			//display: block !important;
			display: none;

			position: fixed;
			left: 0; bottom: 25px;
			padding: 0.5em;

			font-size: 13px;
			line-height: 1.2em;
			background-color: yellow;
			z-index: 10;
		}
	}

	.MhStickyHeader {
		//position: sticky;
		top: 0;
		//z-index: 10;
		pointer-events: none;
		width: 100%;

		&__menubar {
			top: 0;
			width: 100%;
			//padding-top: 1rem;
			//padding-bottom: 1rem;

			transform: translateY(-100%);
		}
		&--isReady &__menubar { transition: all 0.25s ease; }
		&--menubarIsVisible &__menubar { transform: translateY(0%); }
		&--menubarIsVisible &__menubar > * { pointer-events: auto; }
		//&--stickyHeaderIsHidden  &__menubar { transform: translateY(-100%); }

		&__debug { display: none; }
	}

	/*
	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
	*/
</style>
