<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<DgArticleHeader></DgArticleHeader>

	2021-09-24	init

-->

<template>
	<div class="DgArticleHeader contentModule" :class="elmClasses" :data-dev-name="'DgArticleHeader'" v-if="title">
		<div class="DgArticleHeader__inner contentModule__inner" :class="innerElmClasses">
			<div class="DgArticleHeader__content contentModule__content" :class="contentElmClasses">
				<!--
				<div class="DgArticleHeader__closeBtn">
					<DgButton class="DgArticleHeader__closeBtn"
						:type="'cross'"
						@click.native="doEmitCloseArticle"
					></DgButton>
				</div>
				-->
				<div class="DgArticleHeader__overTitle fontStyle--s">
					<h4 class="hideIfEmpty" v-html="overTitle"></h4>
					<span class="DgArticleHeader__line"></span>
				</div>
				<h2 class="DgArticleHeader__title hideIfEmpty richText hyphenate fontStyle--xl" v-html="title"></h2>
				<span class="DgArticleHeader__line fontStyle--s"></span>
			</div>
		</div>
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import dgContentModuleMixin from '@/mixins/dgContentmodule.mixin'
	import DgButton from '@/components/molecules/DgButton.vue'

	export default {
		name: 'DgArticleHeader',
		components: {
			DgButton,
		},
		mixins: [ dgContentModuleMixin ],
		props: {
			//imageObject: [Object, Boolean],
			//DgArticleHeader: [String],
			overTitle: {
				type     : [String],
				default  : '',
				required : false,
			},
			title: {
				type     : [String],
				default  : '',
				required : false,
			},
		},
		data(){
			return {}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
			innerElmClasses(){
				let classes = []

				return classes
			},
			contentElmClasses(){
				let classes = []

				return classes
			},
		},
		methods: {
			handleScroll( doLog = true ){
				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• handleScroll()' )
					//console.log('key:', value)
					console.groupEnd()
				}
			},
			doEmitCloseArticle(){
				EventBus.$emit('doCloseArticleOverlay')
			},
		},
		created(){},
		mounted(){},
		destroyed(){},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.DgArticleHeader { // debug
		[showBorders2] & {}
		/*
		&__content > * { outline: 1px solid red; background-color: fade( red, 50 ); }
		&__overTitle { background-color: fade( red, 50 ); }
		&__title { background-color: fade( orange, 50 ); }
		*/
	}
	.DgArticleHeader { // layout
		&__inner {
			.grid;
			grid-gap: var(--app-gridGap);
		}
		&__content {
			display: flex;
			flex-direction: column
		}
		.DgGrid--cols-1 &__inner { .grid--cols-12; }
		.DgGrid--cols-1 &__content { grid-column: 1 / span 12; }
		.DgGrid--cols-2 &__inner { .grid--cols-6; }
		.DgGrid--cols-2 &__content { grid-column: 1 / span 6; }

		// hSpace
		.DgGrid--cols-1 & {
			padding-left: var(--app-content-hPadding);
			padding-right: var(--app-content-hPadding);
		}
		.DgGrid--cols-2 .DgColumn--index-0 & {
			padding-left: var(--app-content-hPadding);
		}
		.DgGrid--cols-2 .DgColumn--index-1 & {
			padding-right: var(--app-content-hPadding);
		}
		// maxWidth
		.DgGrid--cols-1 & {
			width: 100%;
			max-width: var(--app-content-maxWidth);
			align-self: center;
		}
		.DgGrid--cols-2 .DgColumn--index-0 & {
			width: 100%;
			max-width: calc( ( var(--app-content-maxWidth) - var(--app-gridGap) ) / 2 );
			align-self: flex-end;
		}
		.DgGrid--cols-2 .DgColumn--index-1 & {
			width: 100%;
			max-width: calc( ( var(--app-content-maxWidth) - var(--app-gridGap) ) / 2 );
			align-self: flex-start;
		}

		&__closeBtn {
			position: relative;
			//background-color: fade( red, 25 );
		}
		&__closeBtn .DgButton {
			position: absolute;
			top: calc( var(--DgButton-width) * -0.5 );
			right: calc( var(--app-content-hPadding) * -1 + var(--DgButton-width) * -0.5 );
		}
		&__overTitle {
			display: flex;
			align-items: flex-end;
		}
		&__line {
			flex-grow: 1;
		}
	}
	.DgArticleHeader { // styling
		&__content {
			padding-top: 2.5em;
			padding-bottom: 2.5em;
		}
		&__overTitle h4 {
			margin-right: 0.5em;
		}
		&__line {
			height: 0.3em;
			transform: translateY(-0.25em);
			background-color: currentColor;
		}
		&__title {
			padding-top: 0.16em;
			padding-bottom: 0.5em;
		}
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
