/**
 * Converts newlines to <br/>
 *
 * @param {string} str
 * @returns {string} string
 */
function nl2br(str = '', breakTag = '<br/>'){
    return ( str + '' ).replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}

export default nl2br;