<!--

	Was macht diese Componente?
	Wofür ist die Compoente da?

	Welche $props gibt es?

	Beispiel Code:
		<DgLogoRow></DgLogoRow>

	2022-03-04	init

-->

<template>
	<div class="DgLogoRow contentModule" :class="elmClasses" :data-dev-name="'DgLogoRow • ' + logos.length + ' Logos'">
		<div class="DgLogoRow__inner">
			<div class="DgLogoRow__text" v-html="text"></div>
			<ul class="DgLogoRow__logosList">
				<li class="DgLogoRow__logosListItem" v-for="(logo, index) in logos" :key="index">
					<a class="DgLogoRow__logoLink" v-if="logo.link" :href="logo.link" target="_blank">
						<img class="DgLogoRow__logoImage" :src="_.get( logo, 'image.sizes.medium')" loading="lazy" />
					</a>
					<img v-else class="DgLogoRow__logoImage" :src="_.get( logo, 'image.sizes.medium')" loading="lazy" />
				</li>
			</ul>
		</div>
		<!--
		<pre name="$attrs">{{$attrs}}</pre>
		<pre name="$props">{{$props}}</pre>
		-->
	</div>
</template>

<script>
	import EventBus from '@/helper/EventBus.js'
	import MhImage from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhImage/v4/MhImage.vue'

	export default {
		name: 'DgLogoRow',
		components: {
			MhImage,
		},
		mixins: [],
		props: {
			//imageObject: [Object, Boolean],
			//text: [String],
			text: {
				type     : [String],
				default  : '',
				required : false,
			},
			logos: {
				type     : [Array],
				default  : ()=>{ return []},
				required : false,
			},
		},
		data(){
			return {}
		},
		watch: {
			someVar: {
				handler: function( to, from ) {
				},
				immediate : true,
				deep: true,
			},
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			elmClasses(){
				let classes = []

				//classes.push( this.$options.name + '--isAnimating')

				return classes
			},
		},
		methods: {
			someFunction( doLog = true ){
				// groupCollapsed group
				if( doLog ){
					console.groupCollapsed( this.$options.name, '• handleScroll()' )
					//console.log('key:', value)
					console.groupEnd()
				}
			},
		},
		created(){
			//window.addEventListener('resize', this._.debounce( this.someFunction, 250 ))
			//window.addEventListener('resize', this._.throttle( this.someFunction, 250 ))
		},
		mounted(){},
		destroyed(){
   			//window.removeEventListener('scroll', this.handleScroll);
 		},
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.DgLogoRow { // debug
		[showBorders2] & {}

		[showBorders4] & {
			&__logosList { outline: 1px solid red; }
			&__logoImage { outline: 1px dotted red; }
		}
	}
	.DgLogoRow { // vars
		--vPadding : 2em;
		--logoHeight : 60px;
		--logoGap : 2em;

		@media @mq[md] { --vPadding : 1.75em; }
	}
	.DgLogoRow { // layout
		position: relative;

		@media @mq[dt] {
			&__inner {
				.grid;
				.grid--cols-12;
				grid-gap: var(--app-gridGap);
			}
			&__text {
				grid-column: span 4;
			}
			&__logosList {
				grid-column: span 8;
			}
		}
		@media @mq[md] {
			&__text {
				grid-column: span 12;
			}
			&__logosList {
				grid-column: span 12;
			}
		}

		&__logosList {
			margin: 0; padding: 0;
			list-style: none;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
		}
		&__logosListItem {
			display: inline-block;
		}
		&__logoImage {
			display: block;
			height: var(--logoHeight);
		}
	}
	.DgLogoRow { // styling
		padding-top: var(--vPadding);
    	padding-bottom: var(--vPadding);

		font-size: var(--app-gui-fontSize-s);
		line-height: var(--app-gui-lineHeight-s);

		&__text { padding-bottom: 1em; }
		&__logosListItem { margin-bottom: var(--logoGap); margin-right: var(--logoGap); }
	}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
